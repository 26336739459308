import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Page from "../components/Page";
import * as api from '../api';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  list: {
    backgroundColor: theme.palette.grey['200']
  }
}));

const Home = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [competitions, setCompetitions] = useState([]);
  const [menuCompetition, setMenuCompetition] = useState(null);
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    fetchCompetitions();
  }, []);

   const handleClick = (competition, event) => {
    setMenuCompetition(competition);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchCompetitions = async () => {
    setLoading(true);
    try {
      const result = await api.get('/api/v1/competitions/');
      setCompetitions(result);
      setLoading(false);
    }
    catch (e) {
      setLoading(false);
      setCompetitions([]);
      console.error(e);
    }
  }

  const renderCompetitions = () => {
    if (competitions.length === 0) {
      return (
        <Box my={3}>
          <Typography variant={'body1'} align={'center'}>There are currently no competitions</Typography>
        </Box>
      );
    }
    return (
      <>
        <List className={classes.list}>
          {competitions.map((competition) => {
            return (
              <ListItem divider key={competition.id} onClick={() => history.push(`/competition-live/${competition.id}`)}>
                <ListItemText primary={`${competition.name}`} secondary={competition.state_display} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={(e) => handleClick(competition, e)}>
                    <MoreVertIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => history.push(`/competition-live/active`)}>Live</MenuItem>
          <MenuItem onClick={() => history.push(`/competition-drivers/${menuCompetition.id}`)}>Drivers</MenuItem>
          <MenuItem onClick={() => history.push(`/competition-standings/${menuCompetition.id}`)}>Standings</MenuItem>
          <MenuItem onClick={() => history.push(`/competition-leaderboard/${menuCompetition.id}`)}>Leaderboard</MenuItem>
        </Menu>
      </>
    );
  };

  return (
    <Page title={"Competitions"}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {loading ?
            <LinearProgress />
            :
            <div>
              {renderCompetitions()}
            </div>
          }
        </Grid>
      </Grid>
    </Page>
  );
};

export default Home;
